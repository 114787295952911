//import withRoot from "../utils/withRoot";
import React, {useState, useEffect} from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import SEO from "../components/SEO";
import { makeStyles, Theme, createStyles, CircularProgress, Container, Grid, Typography, CardMedia } from "@material-ui/core";
import {auth, useAuth,  firestore , firebase, useFirestoreQuery, useFirestoreDoc}  from 'gatsby-theme-firebase';
import queryString from 'query-string';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: "bold",
      textTransform: "none"

    },
 
    contentBox: {
      maxWidth: "calc(1136px - 60px)",
      width: "calc(100% - 60px)",
 
    },
    headerSpacer2: {
      flex: 1,
      display: 'fixed',
      marginLeft: '50%',
      height: '350px',
    },
    headerSpacer3: {
      flex: 1,
      display: 'fixed',
      marginTop: '35%',
 
    },
    containerUi: {
    
      marginLeft: -15,

    },
  }),
);
 

const imageUrl = `${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/images%2Fstores%2F`
const postImageUrl = (mainStoreId: string, imageName: string, productId: string, productType: string) => {
  
  const lastDot = imageName.lastIndexOf('.');
  const fileName = imageName.substring(0, lastDot);
  const ext = imageName.substring(lastDot + 1);
    
  const newName = productType !== "ads" ? `thumbnails%2F${fileName}_400x400.${ext}` : `${fileName}.${ext}`;
  const completeName= `${imageUrl}${mainStoreId}%2F${productId}%2F${newName}?alt=media`;
 
  return completeName;

}


const  pvlink = (props) => {

  var [isLoading,setIsLoading] = useState(false);
  var [error, setError] = React.useState<Error | null>(null);
  var [product,setProduct] = useState(null);
  var [storeId, setStoreId] = useState(null);
  var [title,setTitle] = useState(null);
  var [productId,setProductId] = useState(null);
  var [url,setUrl] = useState(null);
  var [description,setDescription] = useState(null);
  var [image,setImage] = useState(null);

  useEffect(()=>{

    if( props && props.location && props.location.search  ){ 
       const params = queryString.parse( props.location.search);
       let _storeId = process.env.GATSBY_DEFUALT_STORE;
     
      //  if(params.store !== 'create' && params.fid ){
         
      //      console.log("setbannerurl1.1: " +params.store);
      //      setStoreId(params.store);
           
            
      //        var _wait = [  firestore.collection("products").doc( params.fid+'').get().then(
      //            function(doc){
      //              if (doc.exists) {
      //               setProduct({ _id: doc.id, ...doc.data()});                                       
      //                console.log("Init store profile null");
      //              } 
      //            }
      //          ).catch( (error)=> {
      //            console.log("Error getting other params store: ", error);
      //          })];
      //        var _response = Promise.all(_wait);
          
      //  }
        if(params.store && params.store !== 'create' && params.fid  && params.prt && params.prd){
            setStoreId(params.store);
            setProductId(params.fid);
            setTitle(params.prt);
            setDescription(params.prd);
            setUrl(`${process.env.GATSBY_HOST}?store=${storeId}&fid=${params.fid}`);
            setImage("https://firebasestorage.googleapis.com/v0/b/miniens-one.appspot.com/o/images%2Fstores%2Fstr-ABoolHgxSnW0AtwaG2wiObdCdmv2%2FddWypHvsrWt5kzIVsTe8%2Fcow.png?alt=media&token=6f83f537-0feb-4a46-b262-82c721baf03a");
        }

     }
  
   });

 
 
  return (
  
     
       <Container>
          
           <SEO title={title} description={description} 
            url={url}
            image={image}
            ></SEO>
        
        
       <Grid container direction="row" justify="center" alignItems="center">  
             
             <Grid item >     
            
                   <Typography variant="h5">   {title} , {description}, {url} </Typography> 
                
                   <img  src={image} />
                
              
             </Grid>
             
           </Grid>
        
    
      </Container>
       
    
  );
  };

 
export default pvlink;
 
